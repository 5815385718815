import React from "react";
import { Button } from '@atoms'
import { defaultResolveRichText } from "@utils/resolve-rich-text/defaultResolveRichText"

export const ModalCaptionContent = ({ reference }) => (
  <div className='video-content'>
    <div className='content'>
      {reference?.kicker && (
        <div className="modal_kicker">{reference?.kicker}</div>
      )}
      {reference?.title && (
        <div className="modal_heading">{reference?.title}</div>
      )}
      {reference?.caption && (
        <div className="modal_subheading">{defaultResolveRichText(reference?.caption)}</div>
      )}
    </div>
    {reference?.ctaButton?.link && 
      <div className='cta-link'>
          <Button
            ctaVariant={reference?.ctaButton?.variant || "secondary"}
            ctaLink={reference?.ctaButton?.link}
            {...reference?.ctaButton}
          >
            {reference?.ctaButton?.label}
          </Button>
      </div>
    }
  </div>
)